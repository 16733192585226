import React, {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from '@hookform/resolvers/joi';
import {Button, Checkbox} from "antd";

import {defaultValidationOptions} from "../validation";
import {ControlledModalFormFooter} from "../ControlledModalFormFooter";
import {isDev} from "../../data/env";
import {setAllValues} from "../../../util/setAllValues";
import {mockConstructionOrder} from "../../data/factories";
import {useSubmitOrderForm} from "../../../hooks/useSubmitOrderForm";
import {FormSection} from "../FormSection";
import {equipmentEndpointDefaults, EquipmentEndpointInput} from "./controls/EquipmentEndpointInput";
import {CreateOrderFormProps} from "./common";
import {RadioGroupInputSlice} from "./slices/RadioGroupInputSlice";
import {contactDefaults, ContactInput} from "./controls/ContactInput";
import {addressDefaults} from "./controls/AddressInput";
import {AddressOrLineIdInputSlice} from "./slices/AddressOrLineIdInputSlice";
import {
  operatorMigrationOrderSchemaWithAddress,
  operatorMigrationOrderSchemaWithLineId
} from "../../../schemas/operatorMigrationSchema";
import {InputRow} from "../InputRow";
import {FormattedDatePicker} from "./controls/FormattedDatePicker";
import {TaskHandlingInputSlice} from "./slices/TaskHandlingInputSlice";

export const CreateOperatorMigrationForm: React.FC<CreateOrderFormProps> = ({hide}) => {

  const [isUsingLineId, setIsUsingLineId] = useState<boolean>(false);

  const {errors, formState, handleSubmit, control, setValue} = useForm({
    defaultValues: {
      fiberCode:               "FIBER_A",
      activeEquipmentEndpoint: equipmentEndpointDefaults,
      contact:                 contactDefaults,
      address:                 addressDefaults,
      lineId:                  1,
      singleConsumer:          true,
      executionDate:           "",
      handle:                  false,
    },
    resolver:      (...args) => {
      const schema = isUsingLineId ? operatorMigrationOrderSchemaWithLineId : operatorMigrationOrderSchemaWithAddress;
      return joiResolver(schema, defaultValidationOptions)(...args) as any;
    },
  });

  const onSubmit = handleSubmit(
    useSubmitOrderForm('OperatorMigrationOrder', 'operatorMigration')
  );

  return <form onSubmit={onSubmit}>
    <FormSection>
      <RadioGroupInputSlice control={control} errors={errors} id={'fiberCode'} options={['FIBER_A', 'FIBER_B']}/>
    </FormSection>
    <FormSection>
      <Controller
        control={control}
        name={'activeEquipmentEndpoint'}
        render={props => <EquipmentEndpointInput errors={errors} {...props}/>}
      />
    </FormSection>
    <FormSection>
      <AddressOrLineIdInputSlice
        errors={errors}
        control={control}
        isUsingLineId={isUsingLineId}
        setIsUsingLineId={setIsUsingLineId}
      />
    </FormSection>
    <FormSection>
      <Controller
        control={control}
        name={'contact'}
        render={props => <ContactInput errors={errors} {...props}/>}
      />
    </FormSection>
    <FormSection>
      <InputRow control={control} errors={errors} columns={[{
        id:     'singleConsumer',
        render: ({value, onChange, ...props}) => <Checkbox
          onChange={e => onChange(e.target.checked)}
          checked={value} {...props}>EndUser is the only consumer of the Connection</Checkbox>,
      }]}/>
    </FormSection>
    <FormSection>
      <InputRow control={control} errors={errors} columns={[{
        id:     'executionDate',
        render: props => <FormattedDatePicker placeholder={'Execution date'} {...props}/>,
      }]}/>
    </FormSection>
    <FormSection>
      <TaskHandlingInputSlice control={control} errors={errors}/>
    </FormSection>
    <ControlledModalFormFooter hide={hide} submitting={formState.isSubmitting}>
      {isDev() ? <Button onClick={() => {
        const order = mockConstructionOrder();
        setAllValues(setValue, order);
      }}>Populate with dummy data</Button> : null}
    </ControlledModalFormFooter>
  </form>;
};
