import joi from "joi";
import {contactSchema, dateSchemaOptional, equipmentEndpointSchema, splitSchemaLocationType} from "./common";

export const portConstructionOrMigration = splitSchemaLocationType(joi.object({
  activeEquipmentEndpoint: equipmentEndpointSchema,
  contact:                 contactSchema,
  fiberCode:               joi.valid('FIBER_A', 'FIBER_B'),
  handle:                  joi.bool(),
}));

export const constructionOrderSchemaWithAddress = portConstructionOrMigration[0].append({
  wishDate: dateSchemaOptional,
});

export const constructionOrderSchemaWithLineId = portConstructionOrMigration[1].append({
  wishDate: dateSchemaOptional,
});
