import joi from "joi";
import {dateSchemaRequired, equipmentEndpointSchema, serviceUidFieldSchema} from "./common";

export const portMigrationOrderSchema = joi.object({
  serviceUid:                  serviceUidFieldSchema,
  fromActiveEquipmentEndpoint: equipmentEndpointSchema,
  toActiveEquipmentEndpoint:   equipmentEndpointSchema,
  executionDate:               dateSchemaRequired,
  handle:                      joi.bool(),
});
